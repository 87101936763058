import React from 'react'
import useTranslations from '@src/hooks/useTranslations'
import { TextField } from '@material-ui/core/'

export default function Email({ touched, errors, handleChange, handleBlur }) {
	const t = useTranslations()
	return (
		<TextField
			label={t('kinto.form.email')}
			id={t('kinto.form.email')}
			variant='outlined'
			name='email'
			required
			onChange={handleChange}
			onBlur={handleBlur}
			error={errors.email && touched.email}
			helperText={errors.email && touched.email && errors.email}
		/>
	)
}
