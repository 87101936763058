import { get, TOKEN_KEY } from '@src/utils/localStorage'
import { getCookie } from '@src/utils/cookieUtil'

export const loadToken = () => {
	const localStorageToken = get(TOKEN_KEY)
	if (localStorageToken) return localStorageToken
	const tokenCookie = getCookie(TOKEN_KEY)
	if (tokenCookie && tokenCookie !== '') return tokenCookie
	return null
}
