import { useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'
import { get } from 'lodash'
import { fetchCustomerInfo } from '@src/api/azureApiGateway/client'
import { del, TOKEN_KEY } from '@src/utils/localStorage'
import { setCookie } from '@src/utils/cookieUtil'
import { setToken } from '@src/api/azureApiGateway/client'

/**
 * Hook for fetching Customer Info.
 */
function useCustomerInfo() {
	const { doSetCustomerInfo, customerInfo, doResetCustomer } = useConnect(
		'doSetCustomerInfo',
		'selectCustomerInfo',
		'doResetCustomer',
	)

	useEffect(() => {
		if (
			!customerInfo.token ||
			customerInfo.token === '' ||
			customerInfo.customerId !== ''
		) {
			return
		}

		;(async () => {
			try {
				const newCustomerInfo = await fetchCustomerInfo()
				doSetCustomerInfo(newCustomerInfo)
			} catch (error) {
				if (get(error, 'response.status') === 401) {
					del(TOKEN_KEY)
					setCookie(TOKEN_KEY, '')
					setToken(null)
					doResetCustomer()
				}
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doSetCustomerInfo, customerInfo])

	return customerInfo
}

export default useCustomerInfo
