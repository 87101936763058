import React from 'react'
import getStore from './src/bundles'
import { ReduxBundlerProvider } from 'redux-bundler-hook'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { themeCustom } from '@src/layouts/theme'
import Layout from '@src/layouts/layout'

const state = {}

const store = getStore(state)
const theme = createMuiTheme(themeCustom)

export const wrapPageElement = ({ element, props }) => {
	return (
		<ThemeProvider theme={theme}>
			<Layout {...props}>{element}</Layout>
		</ThemeProvider>
	)
}

export const wrapRootElement = ({ element }) => {
	return <ReduxBundlerProvider store={store}>{element}</ReduxBundlerProvider>
}

export const onRouteUpdate = ({ location, prevLocation }) => {
	const state = store.getState()
	if (location.pathname === state.search.previousPathname) {
		store.dispatch({ type: 'RESTORE_PREVIOUS_QUERY' })
	}
	if (
		prevLocation &&
		location.pathname !== '/sok' &&
		prevLocation.pathname === '/sok'
	) {
		store.dispatch({ type: 'SET_QUERY', payload: '' })
	}
}
