import {
	azureAuth,
	azureApiGatewayGet,
	fetchCustomerInfo,
} from '@src/api/azureApiGateway/client'
import { loadToken } from '@src/utils/tokenUtils'

import { transformPhonenumber } from '@src/form/transformation'

export default {
	name: 'customer',
	getReducer: () => {
		const initialState = {
			token: loadToken(),
			firstName: '',
			lastName: '',
			phonenumber: '',
			customerId: '',
			email: '',
			sso: '',
			address: [],
			drivingLicense: {},
			showMissingInformation: false,
			unsuccessfulLogin: false,
			creditCards: [],
			defaultCreditCard: null,
			canReserve: null,
			canReserveBusiness: null,
			canSchedule: null,
			canScheduleBusiness: null,
			phonenumberVerified: null,
			validPaymentCard: null,
			loading: false,
			lastError: null,
			driverLicenseExpDate: null,
			businessEntity: {},
			membershipId: null,
		}
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_CUSTOMER_INFO':
					const formattedPayload = {
						...payload,
						phonenumber: payload.phonenumber
							? transformPhonenumber(payload.phonenumber)
							: undefined,
					}
					return { ...state, ...formattedPayload }
				case 'RESET_CUSTOMER_INFO':
					return { ...initialState, token: null }
				case 'SET_SHOW_MISSING_INFORMATION':
					return { ...state, showMissingInformation: payload }
				case 'SET_UNSUCESSFUL_LOGIN':
					return { ...state, unsuccessfulLogin: payload }
				case 'SET_CUSTOMER_CREDIT_CARDS':
					return { ...state, ...payload }
				case 'RESET_CUSTOMER_CREDIT_CARDS':
					return { ...state, creditCards: [], defaultCreditCard: null }
				case 'FETCH_CUSTOMER_INFO_STARTED':
					return { ...state, loading: true, lastError: null }
				case 'FETCH_CUSTOMER_INFO_SUCCESS':
					return { ...state, loading: false, lastError: null }
				case 'FETCH_CUSTOMER_INFO_FAILURE':
					return { ...state, loading: false, lastError: payload }
				case 'SET_MEMBERSHIP':
					return { ...state, ...payload }
				default:
					return state
			}
		}
	},
	doSetCustomerInfo: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_CUSTOMER_INFO', payload })
	},
	doResetCustomer: () => ({ dispatch }) => {
		dispatch({ type: 'RESET_CUSTOMER_INFO', payload: null })
	},
	doRefreshCustomerInfo: () => async ({ dispatch, store }) => {
		dispatch({ type: 'FETCH_CUSTOMER_INFO_STARTED' })
		try {
			const newCustomerInfo = await fetchCustomerInfo()
			dispatch({
				type: 'SET_CUSTOMER_INFO',
				payload: newCustomerInfo,
			})
			await store.doSetCustomerCreditCards()
			dispatch({ type: 'FETCH_CUSTOMER_INFO_SUCCESS' })
			return newCustomerInfo
		} catch (error) {
			dispatch({ type: 'FETCH_CUSTOMER_INFO_FAILED', payload: error })
			throw error
		}
	},
	doLoginCustomer: (email, password, keepCustomerLoggedIn = false) => async ({
		dispatch,
		store,
	}) => {
		try {
			const response = await azureAuth(email, password, keepCustomerLoggedIn)
			if (response.data && response.data.auth_token) {
				dispatch({
					type: 'SET_UNSUCESSFUL_LOGIN',
					payload: false,
				})
				dispatch({
					type: 'SET_CUSTOMER_INFO',
					payload: {
						token: response.data.auth_token,
					},
				})
				return await store.doRefreshCustomerInfo()
			} else {
				throw new Error('Failed login attempt')
			}
		} catch (error) {
			dispatch({
				type: 'SET_UNSUCESSFUL_LOGIN',
				payload: true,
			})
			return false
		}
	},
	doSetShowMissingInformation: (payload) => ({ dispatch }) => {
		dispatch({
			type: 'SET_SHOW_MISSING_INFORMATION',
			payload,
		})
	},
	doSetCustomerCreditCards: () => async ({ dispatch, getState }) => {
		const state = getState()
		const { customerId, token } = state.customer
		const isPossibleToFetch = !!token && customerId && customerId !== ''
		if (isPossibleToFetch) {
			const resp = await azureApiGatewayGet(
				`customer/${customerId}/card`,
				{},
				{ useToken: true },
			)
			let defaultCreditCard = -1
			resp.data.cards.forEach((card) => {
				if (card.in_use && card.is_valid) {
					defaultCreditCard = card
				}
			})
			if (resp.data.cards && resp.data.cards.length > 0) {
				dispatch({
					type: 'SET_CUSTOMER_CREDIT_CARDS',
					payload: { creditCards: resp.data.cards, defaultCreditCard },
				})
			} else {
				dispatch({
					type: 'SET_CUSTOMER_CREDIT_CARDS',
					payload: { creditCards: [], defaultCreditCard },
				})
			}
		}
	},
	doResetCustomerCreditCards: () => ({ dispatch }) => {
		dispatch({ type: 'RESET_CUSTOMER_CREDIT_CARDS', payload: null })
	},
	selectCustomerInfo: (state) => {
		return {
			...state.customer,
		}
	},
	selectIsCustomerLoggedIn: (state) => !!state.customer.token,
	selectShowMissingInformation: (state) =>
		!!state.customer.showMissingInformation,
	selectIsMissingInformation: (state) => {
		if (
			state.customer.canSchedule === null ||
			state.customer.phonenumberVerified === null
		) {
			return null
		}
		if (
			state.customer.canSchedule === undefined ||
			state.customer.canSchedule === null
		) {
			return !state.customer.canReserve || !state.customer.phonenumberVerified
		}
		return !state.customer.canSchedule || !state.customer.phonenumberVerified
	},
	selectIsMissingInformationBusiness: (state) => {
		if (state.customer.canScheduleBusiness === null) {
			return null
		}
		if (
			state.customer.canScheduleBusiness === undefined ||
			state.customer.canScheduleBusiness === null
		) {
			return !state.customer.canReserveBusiness
		}
		return !state.customer.canScheduleBusiness
	},
	selectBusinessCustomer: (state) => {
		if (
			state.customer.businessEntity === {} ||
			state.customer.businessEntity.id === null
		) {
			return false
		}
		return true
	},
	selectUnsuccessfulLogin: (state) => !!state.customer.unsuccessfulLogin,
	selectCustomerCreditCards: (state) => state.customer.creditCards,
	selectCustomerInfoLoading: (state) => state.customer.loading,
	selectMembership: (state) => state.customer.membershipId,
	doSetMembership: () => async ({ dispatch, getState }) => {
		const state = await getState()
		const { customerId, token } = state.customer
		const isPossibleToFetch = !!token && customerId && customerId !== ''
		if (isPossibleToFetch) {
			const headers = {
				'Ocp-Apim-Subscription-Key': process.env.GATSBY_MM_API_GATEWAY_KEY,
				'Telematics-AuthToken': token,
			}
			const response = await fetch(
				`${process.env.GATSBY_MM_API_GATEWAY_BASE_URL}/customer/${customerId}/active_membership/`,
				{ headers },
			)
			const data = await response.json()
			const membershipId =
				data.data &&
				data.data.active_membership !== null &&
				data.data.active_membership.membership_id

			if (membershipId) {
				dispatch({
					type: 'SET_MEMBERSHIP',
					payload: { membershipId: membershipId },
				})
			}
		}
	},
}
