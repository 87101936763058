// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-bli-medlem-js": () => import("./../src/pages/bli-medlem.js" /* webpackChunkName: "component---src-pages-bli-medlem-js" */),
  "component---src-pages-boka-bil-js": () => import("./../src/pages/boka-bil.js" /* webpackChunkName: "component---src-pages-boka-bil-js" */),
  "component---src-pages-cms-preview-js": () => import("./../src/pages/cmsPreview.js" /* webpackChunkName: "component---src-pages-cms-preview-js" */),
  "component---src-pages-kom-igang-js": () => import("./../src/pages/kom-igang.js" /* webpackChunkName: "component---src-pages-kom-igang-js" */),
  "component---src-pages-mitt-kinto-js": () => import("./../src/pages/mitt-kinto.js" /* webpackChunkName: "component---src-pages-mitt-kinto-js" */),
  "component---src-pages-redirect-js": () => import("./../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-sso-js": () => import("./../src/pages/sso.js" /* webpackChunkName: "component---src-pages-sso-js" */),
  "component---src-templates-content-page-js": () => import("./../src/templates/contentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-embeddable-content-page-js": () => import("./../src/templates/embeddableContentPage.js" /* webpackChunkName: "component---src-templates-embeddable-content-page-js" */),
  "component---src-templates-start-page-js": () => import("./../src/templates/startPage.js" /* webpackChunkName: "component---src-templates-start-page-js" */)
}

