import { get } from 'lodash'
import { createSelector } from 'redux-bundler'
import {
	azureApiGatewayGet,
	azureApiGatewayPost,
} from '@src/api/azureApiGateway/client'
import { SITE_TYPES } from '@src/bundles/site'

export default {
	name: 'booking',
	getReducer: () => {
		const initialState = {
			available_vehicles: [],
			selectedStation: null,
			previousSelectedStation: null,
			selectedCar: null,
			startDate: null,
			startTime: null,
			endDate: null,
			endTime: null,
			loading: false,
			lastError: false,
			insurance: null,
			insuranceChecked: null,
			comment: null,
			serviceId: null,
		}
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_SELECTED_STATION':
					const oldState = { ...state }
					if (!payload) {
						return {
							...state,
							selectedStation: payload,
							previousSelectedStation: oldState.selectedStation,
						}
					}
					return {
						...state,
						selectedStation: payload,
					}
				case 'RESET_SELECTED_STATION':
					return {
						...state,
						selectedStation: null,
						previousSelectedStation: state.selectedStation,
						available_vehicles: [],
					}
				case 'SET_BOOKING_SELECTED_CAR':
					return {
						...state,
						selectedCar: payload,
						serviceId: payload.service_id,
					}
				case 'RESET_BOOKING_SELECTED_CAR':
					return { ...state, selectedCar: null, available_vehicles: [] }
				case 'RESET_BOOKING_INFO':
					return { ...initialState }
				case 'SET_START_DATE':
					return { ...state, startDate: payload }
				case 'SET_START_TIME':
					return { ...state, startTime: payload }
				case 'SET_END_DATE':
					return { ...state, endDate: payload }
				case 'SET_END_TIME':
					return { ...state, endTime: payload }
				case 'SET_INSURANCE':
					return { ...state, insurance: payload }
				case 'SET_INSURANCE_CHECKED':
					return { ...state, insuranceChecked: payload }
				case 'SET_COMMENT':
					return { ...state, comment: payload }
				case 'SET_SERVICE_ID':
					return { ...state, serviceId: payload }
				case 'SET_INTERVAL':
					return {
						...state,
						startDate: payload.startDate,
						startTime: payload.startTime,
						endDate: payload.endDate,
						endTime: payload.endTime,
					}
				case 'RESET_INTERVAL':
					return {
						...state,
						startDate: null,
						startTime: null,
						endDate: null,
						endTime: null,
					}
				case 'FETCH_AVAILABLE_VEHICLES_SUCCESS':
					return {
						...state,
						loading: false,
						lastError: null,
						available_vehicles: payload,
					}
				case 'FETCH_AVAILABLE_VEHICLES_STARTED':
					return { ...state, loading: true, lastError: null }
				case 'FETCH_AVAILABLE_VEHICLES_FAILED':
					return { ...state, loading: false, lastError: payload }
				case 'SCHEDULE_RENTAL_SUCCESS':
					return {
						...state,
						loading: false,
						lastError: false,
					}
				case 'SCHEDULE_RENTAL_STARTED':
					return { ...state, loading: true, lastError: null }
				case 'SCHEDULE_RENTAL_FAILED':
					return {
						...state,
						loading: false,
						lastError: payload,
						available_vehicles: [],
					}
				default:
					return state
			}
		}
	},
	doSetBookingSelectedCar: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_BOOKING_SELECTED_CAR', payload })
	},
	doResetBookingSelectedCar: () => ({ dispatch }) => {
		dispatch({ type: 'RESET_BOOKING_SELECTED_CAR' })
	},
	doResetBookingInfo: () => ({ dispatch }) => {
		dispatch({ type: 'RESET_BOOKING_INFO', payload: null })
	},
	doSetStartDate: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_START_DATE', payload })
	},
	doSetStartTime: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_START_TIME', payload })
	},
	doSetEndDate: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_END_DATE', payload })
	},
	doSetEndTime: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_END_TIME', payload })
	},
	doSetInsurance: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_INSURANCE', payload })
	},
	doSetInsuranceChecked: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_INSURANCE_CHECKED', payload })
	},
	doSetComment: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_COMMENT', payload })
	},
	doSetServiceId: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_SERVICE_ID', payload })
	},
	doSetInterval: (payload = null) => async ({ dispatch, store }) => {
		dispatch({ type: 'SET_INTERVAL', payload })
		const { startTime, endTime } = payload
		store.doFetchAvailabelVehicles({ startTime, endTime })
	},
	doResetInterval: () => ({ dispatch }) => {
		dispatch({ type: 'RESET_INTERVAL' })
	},
	doFetchAvailabelVehicles: ({
		startTime = null,
		endTime = null,
	} = {}) => async ({ dispatch, getState, store }) => {
		dispatch({ type: 'FETCH_AVAILABLE_VEHICLES_STARTED' })
		const state = getState()
		const newStartTime = startTime ? startTime : state.booking.startTime
		const newEndTime = endTime ? endTime : state.booking.endTime
		const station = state.booking.selectedStation

		if (newStartTime && newEndTime && station) {
			try {
				const isCustomerLoggedIn = !!state.customer.token
				const response = isCustomerLoggedIn
					? await azureApiGatewayGet(
							`booking/${station.id}/station_available_vehicle_types?start_datetime=${newStartTime}&end_datetime=${newEndTime}`, // start_datetime and end_datetime are added here to avoid URL encoding in axios, APIGateway can't handle it
							{
								rental_mode:
									state.site.bookingType === SITE_TYPES.BUSINESS
										? 'BUSINESS'
										: 'PERSONAL',
							},
							{ useToken: true },
					  )
					: await azureApiGatewayGet(
							`booking/${station.id}/station_visitor_available_vehicle_types?start_datetime=${newStartTime}&end_datetime=${newEndTime}`, // start_datetime and end_datetime are added here to avoid URL encoding in axios, APIGateway can't handle it
							{ rental_mode: 'PERSONAL' },
					  )

				dispatch({
					type: 'FETCH_AVAILABLE_VEHICLES_SUCCESS',
					payload: response.data.vehicle_types,
				})

				const selectedCar = response.data.vehicle_types.find(
					(car) => car.id === state.booking.selectedCar.VehicleType,
				)
				store.doSetServiceId(selectedCar.service_id)
			} catch (error) {
				dispatch({ type: 'FETCH_AVAILABLE_VEHICLES_FAILED', payload: error })
			}
		}
	},
	doSelectStation: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_SELECTED_STATION', payload })
	},
	doResetSelectedStation: () => ({ dispatch }) => {
		dispatch({ type: 'RESET_SELECTED_STATION', payload: null })
	},
	doScheduleRental: () => async ({ dispatch, getState }) => {
		const state = getState()
		const ridecellId = state.booking.selectedCar.RidecellID
		let bookingInformation = {
			customer_id: state.customer.customerId,
			vehicle_type_id: ridecellId ? ridecellId : state.booking.selectedCar.id,
			start_station_id: state.booking.selectedStation.id,
			start_date_time: state.booking.startTime,
			end_date_time: state.booking.endTime,
		}
		if (state.booking.insurance.is_terms_condition_mandatory === false) {
			bookingInformation.addon_data = [
				{
					addon_id: state.booking.insurance.id,
					is_terms_accepted: false,
				},
			]
		} else if (state.booking.insurance.is_terms_condition_mandatory === true) {
			bookingInformation.addon_data = [
				{
					addon_id: state.booking.insurance.id,
					is_terms_accepted: state.booking.insuranceChecked,
				},
			]
		}
		if (state.booking.comment !== null && state.booking.comment !== '') {
			bookingInformation.rental_reason = state.booking.comment
		}
		try {
			dispatch({ type: 'SCHEDULE_RENTAL_STARTED' })
			await azureApiGatewayPost(
				`booking/${state.booking.serviceId}/scheduled_rental`,
				bookingInformation,
				{ useToken: true },
			)
			dispatch({
				type: 'SCHEDULE_RENTAL_SUCCESS',
			})
		} catch (error) {
			dispatch({ type: 'SCHEDULE_RENTAL_FAILED', payload: error })
			throw error
		}
	},
	selectStartDate: (state) => state.booking.startDate,
	selectStartTime: (state) => state.booking.startTime,
	selectEndDate: (state) => state.booking.endDate,
	selectEndTime: (state) => state.booking.endTime,
	selectSelectedStation: (state) => state.booking.selectedStation,
	selectPreviousSelectedStation: (state) =>
		state.booking.previousSelectedStation,
	selectAvailableCars: (state) => state.booking.available_vehicles,
	selectBookingInfo: (state) => {
		return {
			...state.booking,
		}
	},
	selectIsCarSelected: (state) => state.booking.selectedCar !== null,
	selectSelectedCar: (state) => {
		const selectedRidecellId = get(state, 'booking.selectedCar.RidecellID')
		const car = state.booking.available_vehicles.find(
			(vehicle_type) => vehicle_type.id === selectedRidecellId,
		)

		return {
			...state.booking.selectedCar,
			...car,
		}
	},
	selectIsSelectedCarAvailable: createSelector(
		'selectSelectedCar',
		'selectAvailableCars',
		(selectedCar, availableCars) => {
			return availableCars.some((car) => {
				return (
					car.id ===
					(selectedCar.RidecellID ? selectedCar.RidecellID : selectedCar.id)
				)
			})
		},
	),
	selectBookingLoading: (state) => state.booking.loading,
}
