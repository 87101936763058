export const transformPhonenumber = (phoneNumber) => {
	if (phoneNumber.indexOf('07') === 0) {
		return `+46${phoneNumber.slice(1)}`
	} else if (phoneNumber.indexOf('00') === 0) {
		return `+46${phoneNumber.slice(2)}`
	}
	return phoneNumber
		.trim()
		.replace(/ /g, '')
		.replace(/-/g, '')
}

export const transformDateString = (dateString) => {
	const dateParts = dateString.split(/[.-]+/)
	const date =
		dateParts[0].length === 4
			? new Date(dateString)
			: new Date(dateParts[2], dateParts[1] - 1, dateParts[0])

	if (isNaN(date.getTime())) {
		return dateString
	}

	const day = date.getDate()
	const month = date.getMonth() + 1
	const year = date.getFullYear()
	const parsedDate =
		dateParts[0].length === 2
			? `${pad(day)}.${pad(month)}.${pad(year)}`
			: `${pad(year)}.${pad(month)}.${pad(day)}`

	return parsedDate
}

export const transformDateStringRidecell = (expDate) => {
	const dateParts = expDate.split(/[.-]+/)
	const date =
		dateParts[0].length === 4
			? new Date(expDate)
			: new Date(dateParts[2], dateParts[1] - 1, dateParts[0])

	const day = date.getDate()
	const month = date.getMonth() + 1
	const year = date.getFullYear()
	const parsedDate = `${pad(year)}-${pad(month)}-${pad(day)}`

	return parsedDate
}

function pad(num) {
	return num < 10 ? `0` + num : num
}

export const transformSso = (_sso) => {
	if (!_sso || _sso === '') {
		return ''
	}
	let sso = _sso
	const delimiterIndex = sso.indexOf('-')
	if (delimiterIndex <= 0) {
		sso = `${sso.slice(0, sso.length - 4)}-${sso.slice(-4)}`
	}
	if (sso.length < 13) {
		let year = parseInt('20' + sso.slice(0, 2))
		const now = new Date()
		sso = `${now.getFullYear() - year < 0 ? '19' : '20'}${sso}`
	}
	return sso
}
