import React from 'react'
import Link from '@src/components/Link'
import shareLogo from '../assets/images/Kinto.png'
import flexLogo from '../assets/images/Kinto-flex.png'
import { useConnect } from 'redux-bundler-hook'
import { SITE_TYPES } from '@src/bundles/site'
import { localizedUrl } from '@src/utils/navigate'

const Logo = () => {
	const { siteType, language, service } = useConnect(
		'selectSiteType',
		'selectLanguage',
		'selectService',
	)
	return (
		<Link
			to={
				siteType === SITE_TYPES.PRIVATE && language === 'sv'
					? localizedUrl('/', language, service)
					: siteType === SITE_TYPES.BUSINESS
					? localizedUrl('/foretag', language, service)
					: siteType === SITE_TYPES.PRIVATE && `/${language}`
			}
			tabIndex='0'
			aria-label='Logo'
		>
			<img
				src={service === SITE_TYPES.SHARE ? shareLogo : flexLogo}
				alt='Kintos'
			/>
		</Link>
	)
}

export default Logo
