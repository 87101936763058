export default {
	name: 'site',
	getReducer: () => {
		const initialState = {
			type: SITE_TYPES.PRIVATE,
			bookingType: SITE_TYPES.PRIVATE,
			language: 'sv',
			service: SITE_TYPES.SHARE,
		}
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_TYPE':
					return { ...state, type: payload }
				case 'SET_BOOKING_TYPE':
					return { ...state, bookingType: payload }
				case 'SET_LANGUAGE':
					return { ...state, language: payload }
				case 'SET_SERVICE':
					return { ...state, service: payload }
				default:
					return state
			}
		}
	},
	doSetSiteType: (type) => ({ dispatch }) => {
		dispatch({ type: 'SET_TYPE', payload: type })
	},
	selectSiteType: (state) => state.site.type,
	doSetBookingSiteType: (type) => ({ dispatch }) => {
		dispatch({ type: 'SET_BOOKING_TYPE', payload: type })
	},
	selectBookingSiteType: (state) => state.site.bookingType,
	doSetLanguage: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_LANGUAGE', payload })
	},
	selectLanguage: (state) => state.site.language,
	doSetService: (payload) => ({ dispatch }) => {
		dispatch({ type: 'SET_SERVICE', payload })
	},
	selectService: (state) => state.site.service,
}

export const SITE_TYPES = {
	PRIVATE: 'private',
	BUSINESS: 'business',
	SHARE: 'share',
	FLEX: 'flex',
}
