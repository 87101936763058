import './FormContainer.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('form-container')

const FormContainer = ({ children, twoColumns }) => {
	return (
		<div {...bem('')}>
			{twoColumns ? (
				<div {...bem('two')}>{children}</div>
			) : (
				<div {...bem('one')}>{children}</div>
			)}
		</div>
	)
}

export default FormContainer
