import './Chat.scss'
import React, { useEffect, useState } from 'react'
import { useScript } from 'use-hooks'

import useTranslations from '@src/hooks/useTranslations'
import useSetting from '@src/hooks/useSetting'
import chatIcon from '@src/assets/images/chat_icon.jpg'
import { SITE_TYPES } from '@src/bundles/site'

export default function Chat({ siteType }) {
	const t = useTranslations()
	const getSetting = useSetting()

	const [loadedUrl, loadUrlError] = useScript(
		'https://service.force.com/embeddedservice/5.0/esw.min.js',
	)

	const [consentGiven, setConsentGiven] = useState(false)

	const chatId = getSetting('chat_id', null)
	const chatDeploymentId = getSetting('chat_deployment_id', null)
	const chatButtonId = getSetting('chat_button_id', null)
	const chatBaseLiveAgentContentURL = getSetting(
		'chat_base_live_agent_content_url',
		null,
	)
	const chatSalesforceAccountUrl = getSetting(
		'chat_salesforce_account_url',
		null,
	)
	const chatInfoUrl = getSetting('chat_info_url', null)
	const chatBaseLiveAgentURL = getSetting('chat_base_live_agent__url', null)
	const chatType = getSetting('chat_type', null)
	const chatButtonIdBusiness = getSetting('chat_button_id_business', null)
	const chatTypeBusiness = getSetting('chat_type_business', null)
	const chatEswLiveAgentDev = getSetting('chat_esw_live_agent_dev', null)
	const chatEswLiveAgentDevBusiness = getSetting(
		'chat_esw_live_agent_dev_business',
		null,
	)

	useEffect(() => {
		waitThenRun('OneTrust', () => {
			const functionalCookiesGroupId = 'C0003'
			if (
				window.OnetrustActiveGroups.split(',').includes(
					functionalCookiesGroupId,
				)
			) {
				setConsentGiven(true)
			}

			window.OneTrust.OnConsentChanged((event) => {
				if (event.detail.includes(functionalCookiesGroupId)) {
					setConsentGiven(true)
				}
			})
		})
	}, [])

	useEffect(() => {
		if (
			typeof window !== undefined &&
			consentGiven &&
			loadedUrl &&
			!loadUrlError
		) {
			const initESW = (gslbBaseURL) => {
				const embedded_svc = window.embedded_svc
				if (!embedded_svc) {
					throw new Error('Unable to load Chat')
				}
				embedded_svc.settings.displayHelpButton = true
				embedded_svc.settings.language = 'sv_SE'

				/**
				 * Available configuration settings for Chatt-bot
				 *
				 * embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
				 * embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
				 *
				 * embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
				 * embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
				 *
				 * Settings for Chat
				 * embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
				 *      Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
				 *      Returns a valid button ID.
				 * };
				 * embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
				 * embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
				 */

				embedded_svc.settings.defaultMinimizedText = t(
					'kinto.chat.minimized-text',
				)
				embedded_svc.settings.enabledFeatures = ['LiveAgent']
				embedded_svc.settings.entryFeature = 'LiveAgent'
				embedded_svc.settings.storageDomain = 'kinto-mobility.se'
				embedded_svc.settings.avatarImgURL = chatIcon
				embedded_svc.settings.chatbotAvatarImgURL = chatIcon
				embedded_svc.settings.loadingText = 'Laddar'

				embedded_svc.init(
					chatSalesforceAccountUrl,
					chatInfoUrl,
					gslbBaseURL,
					chatId,
					siteType === SITE_TYPES.BUSINESS ? chatTypeBusiness : chatType,
					{
						baseLiveAgentContentURL: chatBaseLiveAgentContentURL,
						deploymentId: chatDeploymentId,
						buttonId:
							siteType === SITE_TYPES.BUSINESS
								? chatButtonIdBusiness
								: chatButtonId,
						baseLiveAgentURL: chatBaseLiveAgentURL,
						eswLiveAgentDevName:
							siteType === SITE_TYPES.BUSINESS
								? chatEswLiveAgentDevBusiness
								: chatEswLiveAgentDev,
						isOfflineSupportEnabled: false,
					},
				)
			}
			initESW('https://service.force.com')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadedUrl, loadUrlError, consentGiven])

	return <div />
}

function waitThenRun(objectToWaitFor, callback) {
	const interval = setInterval(function() {
		if (window[objectToWaitFor] !== undefined) {
			clearInterval(interval)
			callback()
		}
	})
}
