import { createSelector } from 'redux-bundler'

export default {
	name: 'vehicleTypes',
	getReducer: () => {
		const initialState = {
			vehicleTypes: [],
		}
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_VEHICLE_TYPES':
					return { ...state, vehicleTypes: payload }
				default:
					return state
			}
		}
	},
	doInitializeVehicleTypes: (vehicleTypes) => ({ dispatch }) => {
		dispatch({ type: 'SET_VEHICLE_TYPES', payload: vehicleTypes })
	},
	selectAllVehicleTypes: (state) => state.vehicleTypes.vehicleTypes,
	selectSelectedVehicleType: createSelector(
		'selectAllVehicleTypes',
		'selectSelectedCar',
		(allVehicleTypes, selectedCar) =>
			allVehicleTypes.find(
				(vehicleType) =>
					vehicleType.RidecellID === selectedCar && selectedCar.id,
			) || {
				id: '',
				VehicleType: '',
				Brand: '',
				AddtionalInformation: '',
				Model: '',
				NoOfSeats: '',
				RatePerHour: '',
				RatePerKilometer: '',
				RatePerHourBusiness: '',
				RatePerKilometerBusiness: '',
				FlexPrice: '',
				FlexPriceBusiness: '',
				FlexPriceExtra: '',
				RidecellID: '',
				ModalDocumentLink: {
					path: '',
				},
				Images: {
					image: {
						filename: '',
						fullpath: '',
						srcset: {
							descriptor: '',
							url: '',
							resolutions: {
								url: '',
								resolution: '',
							},
						},
					},
				},
				Size: '',
				Towbar: '',
				MainImage: {
					fullpath: '',
				},
			},
	),
}
