import './MissingInformationModal.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import { navigate } from 'gatsby'
import useCustomerInfo from '@src/hooks/useCustomerInfo'
import useTranslations from '@src/hooks/useTranslations'
import Button from '@material-ui/core/Button'
import image from '@src/assets/images/Kinto_missing_information.jpg'
import Modal from '@src/components/Modal'

const bem = new BEMHelper('missing-information')

function MissingInformationModal({ open, handleClose }) {
	const customerInfo = useCustomerInfo()
	const t = useTranslations()

	return (
		<Modal open={open} handleClose={handleClose} bemClass={'small'}>
			<div {...bem('')}>
				<div {...bem('image')}>
					<img src={image} alt='' />
				</div>
				<div {...bem('title')}>
					<h1 className='h4'>{t('kinto.my-page.finish-profile-title')}</h1>
				</div>
				<p {...bem('text')}>{t('kinto.my-page.finish-profile-text')}</p>
				<Button
					aria-label={t('kinto.my-page.finish-registration')}
					variant='contained'
					color='primary'
					fullWidth
					onClick={() => {
						handleClickFinishRegistration(customerInfo)
						handleClose()
					}}
				>
					{t('kinto.my-page.finish-registration')}
				</Button>
				<Button
					aria-label={t('kinto.general.later')}
					variant='outlined'
					color='primary'
					fullWidth
					onClick={() => handleClose()}
				>
					{t('kinto.general.later')}
				</Button>
			</div>
		</Modal>
	)
}

export default MissingInformationModal

export function handleClickFinishRegistration(customerInfo) {
	const selectPage = getSelectPage(customerInfo)

	if (selectPage) {
		navigate('bli-medlem', {
			state: { selectPage: selectPage },
			replace: true,
		})
	}
}

export function getSelectPage(customerInfo) {
	if (!customerInfo.phonenumberVerified) {
		return 3
	}
	if (!customerInfo.drivingLicense.is_license_verified) {
		return 4
	}
	if (!customerInfo.validPaymentCard) {
		return 6
	}
}
