import './Footer.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import useTranslations from '@src/hooks/useTranslations'
import Logo from '@src/components/Logo'
import Link from '@src/components/Link'
import useSetting from '@src/hooks/useSetting'
import { useConnect } from 'redux-bundler-hook'

const bem = new BEMHelper('footer')

const query = graphql`
	query getFooterSections {
		allFooterSection {
			edges {
				node {
					id
					label
					url
					hasParent
					childFooterSections {
						label
						url
					}
					language
				}
			}
		}
	}
`

const Footer = ({ location = {} }) => {
	const t = useTranslations()
	const { allFooterSection } = useStaticQuery(query)
	const getSetting = useSetting()
	const instagramLink = getSetting('footer_instagram_link', '')
	const facebookLink = getSetting('footer_facebook_link', '')
	const twitterLink = getSetting('footer_twitter_link', '')
	const linkedInLink = getSetting('footer_linkedin_link', '')
	const { language } = useConnect('selectLanguage')

	if (location.pathname && location.pathname.indexOf('/stations') >= 0)
		return null

	const columns = allFooterSection.edges
		.filter(({ node }) => node.language === language)
		.map(({ node }, index) => {
			const links = node.childFooterSections.map(({ url, label }) => {
				return (
					<Link to={url} {...bem('link')} key={url} label={label}>
						{label}
					</Link>
				)
			})

			return (
				<div {...bem('columns')} key={node.id}>
					<>
						<h5 {...bem('column-header')}>{node.label}</h5>
						<div {...bem('link-section')}>{links}</div>
					</>
				</div>
			)
		})

	return (
		<footer {...bem('')}>
			<Container>
				<div {...bem('column-section')}>{columns}</div>
				<div {...bem('extra')}>
					<div {...bem('socialMedia')}>
						{instagramLink && (
							<Link to={instagramLink} aria-label='Instagram'>
								<InstagramIcon fontSize='large' />
							</Link>
						)}
						{facebookLink && (
							<Link to={facebookLink} aria-label='Facebook'>
								<FacebookIcon fontSize='large' />
							</Link>
						)}
						{twitterLink && (
							<Link to={twitterLink} aria-label='Twitter'>
								<TwitterIcon fontSize='large' />
							</Link>
						)}
						{linkedInLink && (
							<Link to={linkedInLink} aria-label='LinkedIn'>
								<LinkedInIcon fontSize='large' />
							</Link>
						)}
					</div>
					<p>{t('kinto.footer')}</p>
					<div {...bem('branding')}>
						<Logo />
					</div>
				</div>
			</Container>
		</footer>
	)
}

Footer.propTypes = {
	children: PropTypes.any,
}

export default Footer
