import './Header.scss'
import React, { useState, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import useSetting from '@src/hooks/useSetting'
import Logo from '@src/components/Logo'
import { graphql, useStaticQuery } from 'gatsby'
import { ReactComponent as Close } from '@src/assets/icons/cancel.svg'
import Link from '@src/components/Link'
import { navigate } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import { ReactComponent as Location } from '@src/assets/icons/geolocation.svg'
import { ReactComponent as User } from '@src/assets/icons/users.svg'
import { ReactComponent as Menu } from '@src/assets/icons/menu.svg'
import useTranslations from '@src/hooks/useTranslations'
import LoginModal from '@src/components/LoginModal'
import { Button } from '@material-ui/core/'
import { googleAnalytics } from '@src/api/googleanalytics/googleAnalytics'
import MissingInformationModal from '@src/components/account/MissingInformationModal'
import Modal from '@src/components/Modal'
import { SITE_TYPES } from '@src/bundles/site'
import events, { EVENT_SHOW_LOGIN_MODAL } from '@src/utils/events'
import useCustomerInfo from '@src/hooks/useCustomerInfo'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { localizedUrl } from '@src/utils/navigate'
import Chat from '@src/components/Chat'
import BookingModal from '@src/components/booking/BookingModal'

const bem = new BEMHelper('header')
const getHeaderQuery = graphql`
	query getPrivateNavigationLinks {
		allPrivateNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allPrivateSecondaryNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allBusinessNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allBusinessSecondaryNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allFlexNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allFlexSecondaryNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allFlexBusinessNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allFlexBusinessSecondaryNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
	}
`

let callbackFn = () => {}
let closeCallbackFn

const Header = ({ location }) => {
	const t = useTranslations()
	const getSetting = useSetting()
	const customerInfo = useCustomerInfo()
	const embeddable = location && location.pathname.endsWith('-embeddable')
	const isStationsPage = location && location.pathname.indexOf('boka-bil') >= 0
	const [showModal, setShowModal] = useState(false)
	const [languageMobileOpen, setLanguageMobileOpen] = useState(false)
	const [showRedirectInfoText, setShowRedirectInfoText] = useState(false)
	const [showTimeOutText, setShowTimeOutText] = useState(false)
	const [hideRegistration, setHideRegistration] = useState(false)
	const [
		returnToBookingAfterRegistration,
		setReturnToBookingAfterRegistration,
	] = useState(false)
	const [showLanguageModal, setShowLanguageModal] = useState(false)
	const [showBookingModal, setShowBookingModal] = useState(false)
	const [navigationLinks, setNavigationLinks] = useState()
	const [navigationLinksSecond, setNavigationLinksSecond] = useState()
	const {
		isCustomerLoggedIn,
		showMissingInformation,
		doSetShowMissingInformation,
		siteType,
		doSetSiteType,
		isMissingInformation,
		isMissingInformationBusiness,
		doSetBookingSiteType,
		doSetLanguage,
		language,
		businessCustomer,
		service,
		doSetService,
		doResetBookingInfo,
		bookingInfo,
		doSetMembership,
	} = useConnect(
		'selectIsCustomerLoggedIn',
		'selectShowMissingInformation',
		'doSetShowMissingInformation',
		'selectSiteType',
		'doSetSiteType',
		'selectIsMissingInformation',
		'selectIsMissingInformationBusiness',
		'doSetBookingSiteType',
		'doSetLanguage',
		'selectLanguage',
		'selectBusinessCustomer',
		'selectService',
		'doSetService',
		'doResetBookingInfo',
		'selectBookingInfo',
		'doSetMembership',
	)
	const languageArray =
		service === SITE_TYPES.SHARE
			? getSetting('languages', null).split(',')
			: getSetting('languagesFlex', null).split(',')

	const flexBookLink = getSetting('flexBookCarLink', null)
	const flexBusinessBookLink = getSetting('flexBusinessBookCarLink', null)
	const showFlexBusinessModal = getSetting('showFlexBusinessModal', null)
	const salesforceLink = getSetting('salesforce_mypages')

	useEffect(() => {
		if (customerInfo.customerId !== '') {
			doSetMembership()
		}
	}, [customerInfo.customerId]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			isCustomerLoggedIn &&
			businessCustomer &&
			siteType === SITE_TYPES.BUSINESS
		) {
			doSetBookingSiteType(SITE_TYPES.BUSINESS)
		} else {
			doSetBookingSiteType(SITE_TYPES.PRIVATE)
		}
	}, [siteType, businessCustomer]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search)
		if (
			businessCustomer &&
			isMissingInformationBusiness === true &&
			!location.pathname.endsWith('/kom-igang') &&
			!location.pathname.endsWith('/kom-igang/') &&
			!urlParams.has('SAMLRequest')
		) {
			doSetShowMissingInformation(true)
		} else if (
			!businessCustomer &&
			isMissingInformation === true &&
			!location.pathname.endsWith('/kom-igang') &&
			!location.pathname.endsWith('/kom-igang/') &&
			!urlParams.has('SAMLRequest')
		) {
			doSetShowMissingInformation(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isMissingInformation,
		isMissingInformationBusiness,
		doSetShowMissingInformation,
	])

	const headerQuery = useStaticQuery(getHeaderQuery)
	const privateNavigationLinks = headerQuery.allPrivateNavigationLink.edges.map(
		(privateNavigationLink) => privateNavigationLink.node,
	)
	const privateNavigationLinksSecond = headerQuery.allPrivateSecondaryNavigationLink.edges.map(
		(privateNavigationLink) => privateNavigationLink.node,
	)
	const businessNavigationLinks = headerQuery.allBusinessNavigationLink.edges.map(
		(businessNavigationLink) => businessNavigationLink.node,
	)
	const businessNavigationLinksSecond = headerQuery.allBusinessSecondaryNavigationLink.edges.map(
		(businessNavigationLink) => businessNavigationLink.node,
	)
	const flexNavigationLinks = headerQuery.allFlexNavigationLink.edges.map(
		(flexNavigationLink) => flexNavigationLink.node,
	)
	const flexNavigationLinksSecond = headerQuery.allFlexSecondaryNavigationLink.edges.map(
		(flexNavigationLink) => flexNavigationLink.node,
	)
	const flexBusinessNavigationLinks = headerQuery.allFlexBusinessNavigationLink.edges.map(
		(flexNavigationLink) => flexNavigationLink.node,
	)
	const flexBusinessNavigationLinksSecond = headerQuery.allFlexBusinessSecondaryNavigationLink.edges.map(
		(flexNavigationLink) => flexNavigationLink.node,
	)

	useEffect(() => {
		if (service === SITE_TYPES.FLEX && siteType === SITE_TYPES.BUSINESS) {
			setNavigationLinks(flexBusinessNavigationLinks)
			setNavigationLinksSecond(flexBusinessNavigationLinksSecond)
		} else if (service === SITE_TYPES.FLEX) {
			setNavigationLinks(flexNavigationLinks)
			setNavigationLinksSecond(flexNavigationLinksSecond)
		} else if (siteType === SITE_TYPES.PRIVATE) {
			setNavigationLinks(privateNavigationLinks)
			setNavigationLinksSecond(privateNavigationLinksSecond)
		} else if (siteType === SITE_TYPES.BUSINESS) {
			setNavigationLinks(businessNavigationLinks)
			setNavigationLinksSecond(businessNavigationLinksSecond)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [siteType, service])

	const handleMyPagesClick = () => {
		if (isCustomerLoggedIn && salesforceLink === '') {
			navigate(localizedUrl('/mitt-kinto', language, service))
		} else if (isCustomerLoggedIn && salesforceLink !== '') {
			window.location.replace(salesforceLink)
		} else {
			setShowModal(true)
		}
	}

	const handleLanguageClick = () => {
		setShowLanguageModal(true)
	}

	const handleBookingClick = () => {
		if (service === SITE_TYPES.FLEX) {
			doResetBookingInfo()
			if (siteType === SITE_TYPES.PRIVATE) {
				navigate(localizedUrl(flexBookLink, language, service))
			} else {
				navigate(localizedUrl(flexBusinessBookLink, language, service))
			}
		} else {
			navigate(localizedUrl('/boka-bil', language, service))
		}
	}

	const [menuExpanded, setMenuExpanded] = useState(false)

	const toggleMenu = ({ force } = {}) => {
		setMenuExpanded(force === false || force === true ? force : !menuExpanded)
		if (location && location.pathname.localeCompare('/') === 0) {
			googleAnalytics.pushDropDownFirstPage()
		}
	}

	const showModalWrapper = ({
		hideRegistration = false,
		callback = () => {},
		closeCallback,
		showRedirectInfoText = false,
		returnToBookingAfterRegistration = false,
		showTimeOutText = false,
	} = {}) => {
		callbackFn = callback
		closeCallbackFn = closeCallback
		setHideRegistration(hideRegistration)
		setShowModal(true)
		setShowRedirectInfoText(showRedirectInfoText)
		setReturnToBookingAfterRegistration(returnToBookingAfterRegistration)
		setShowTimeOutText(showTimeOutText)
	}

	useEffect(() => {
		languageArray.forEach((language) => {
			if (location.pathname.startsWith(`/${language}`)) {
				doSetLanguage(language)
			}
		})

		if (
			location.pathname.startsWith('/flex') ||
			(language !== 'sv' && location.pathname.startsWith(`/${language}/flex`))
		) {
			doSetService(SITE_TYPES.FLEX)
		}
	}, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			location.pathname.startsWith('/foretag') ||
			(language !== 'sv' &&
				location.pathname.startsWith(`/${language}/foretag`))
		) {
			doSetSiteType(SITE_TYPES.BUSINESS)
		}

		events.on(EVENT_SHOW_LOGIN_MODAL, showModalWrapper)
		return () => events.removeListener(EVENT_SHOW_LOGIN_MODAL, showModalWrapper)
	}, [language]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			service === SITE_TYPES.FLEX &&
			bookingInfo.selectedCar === null &&
			(location.pathname.endsWith('/boka-bil') ||
				location.pathname.endsWith('/boka-bil/'))
		) {
			if (siteType === SITE_TYPES.PRIVATE) {
				navigate(localizedUrl(flexBookLink, language, service))
			} else {
				navigate(localizedUrl(flexBusinessBookLink, language, service))
			}
		}
	}, [service, bookingInfo.selectedCar]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			isCustomerLoggedIn &&
			businessCustomer &&
			!isMissingInformationBusiness &&
			isMissingInformationBusiness !== null &&
			localStorage.getItem('hide-popup') !== 'true' &&
			location.pathname.endsWith('/boka-bil') &&
			service !== SITE_TYPES.FLEX
		) {
			setShowBookingModal(true)
		} else if (
			isCustomerLoggedIn &&
			businessCustomer &&
			!isMissingInformationBusiness &&
			isMissingInformationBusiness !== null &&
			localStorage.getItem('hide-popup') !== 'true' &&
			location.pathname.endsWith('/boka-bil') &&
			service === SITE_TYPES.FLEX &&
			showFlexBusinessModal === 'true'
		) {
			setShowBookingModal(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isCustomerLoggedIn,
		customerInfo.businessEntity,
		isMissingInformationBusiness,
		location.pathname,
	])

	return (
		<>
			<BookingModal
				open={showBookingModal}
				handleClose={() => setShowBookingModal(false)}
			/>
			{!embeddable && !isStationsPage && <Chat siteType={siteType} />}
			<nav {...bem('')}>
				<div {...bem('type-wrapper-desktop')}>
					<Link
						{...bem('link', 'type-desktop', 'active service')}
						to={
							service === SITE_TYPES.SHARE
								? localizedUrl('/flex', language)
								: localizedUrl('/', language)
						}
						onClick={() => {
							service === SITE_TYPES.SHARE
								? doSetService(SITE_TYPES.FLEX)
								: doSetService(SITE_TYPES.SHARE)
							doResetBookingInfo()
						}}
					>
						{flexBookLink === ''
							? ''
							: service === SITE_TYPES.SHARE
							? t('kinto.header.flex')
							: t('kinto.header.share')}
					</Link>

					<div>
						<TypeLinks
							siteType={siteType}
							doSetSiteType={doSetSiteType}
							bemClass={'type-desktop'}
							language={language}
							service={service}
						/>

						{languageArray.length > 1 && (
							<>
								<button onClick={handleLanguageClick}>
									<p {...bem('link', 'type-desktop', 'active')}>
										{language.toUpperCase()}
										{' - '}
										{t('kinto.menu.change-language')}
									</p>
								</button>
								<Modal
									open={showLanguageModal}
									handleClose={() => {
										setShowLanguageModal(false)
									}}
									xButton
									bemClass={'language'}
								>
									<h4 {...bem('language-modal-header')}>
										{t(`kinto.choose-language`)}
									</h4>
									{languageArray.map((item) => {
										return (
											<Link
												{...bem('language-modal-language')}
												onClick={() => {
													setShowLanguageModal(false)
													doSetLanguage(item)
													doSetService(SITE_TYPES.SHARE)
												}}
												to={item === 'sv' ? '/' : `/${item}`}
												key={item}
											>
												<h4>{t(`kinto.choose-language.${item}`)}</h4>
											</Link>
										)
									})}
								</Modal>
							</>
						)}
					</div>
				</div>
				<div {...bem('content', { expanded: menuExpanded })}>
					<div {...bem('branding')}>
						<Logo />
					</div>
					<div {...bem('nav-wrapper')}>
						<div {...bem('nav-actions')}>
							<Button
								aria-label={t('kinto.header.book-car')}
								color='primary'
								variant='contained'
								onClick={() => {
									handleBookingClick()
								}}
							>
								<span {...bem('mobile')}>
									<Location aria-hidden='true' />
									<p className='tiny'>
										{service === SITE_TYPES.FLEX
											? t('kinto.header.flex.book-car')
											: t('kinto.header.book-car')}{' '}
									</p>
								</span>
								<span {...bem('desktop')}>
									{service === SITE_TYPES.FLEX
										? t('kinto.header.flex.book-car')
										: t('kinto.header.book-car')}
								</span>
							</Button>
							<Button
								color='primary'
								variant='outlined'
								aria-label={t('kinto.header.my-pages')}
								onClick={handleMyPagesClick}
							>
								<span {...bem('mobile')}>
									<User aria-hidden='true' />
									<p className='tiny'>
										{isCustomerLoggedIn
											? t('kinto.header.my-pages')
											: t('kinto.header.login')}
									</p>
								</span>
								<span {...bem('desktop')}>
									<p>
										{isCustomerLoggedIn
											? t('kinto.header.my-pages')
											: t('kinto.header.login')}
									</p>
								</span>
							</Button>

							<LoginModal
								open={showModal}
								hideRegistration={hideRegistration}
								callback={callbackFn}
								handleClose={() => {
									setShowModal(false)
									if (closeCallbackFn) {
										closeCallbackFn()
										closeCallbackFn = null
										callbackFn = () => {}
									}
								}}
								showRedirectInfoText={showRedirectInfoText}
								returnToBookingAfterRegistration={
									returnToBookingAfterRegistration
								}
								showTimeOutText={showTimeOutText}
							/>
							<MissingInformationModal
								open={showMissingInformation}
								handleClose={() => doSetShowMissingInformation(false)}
							/>
						</div>
						<button
							type='button'
							aria-label={t('kinto.header.menu')}
							onClick={toggleMenu}
							{...bem('burger', { expanded: menuExpanded })}
						>
							<Menu aria-hidden='true' />
						</button>
						<div
							role='button'
							tabIndex={-1}
							{...bem('nav-menu-backdrop')}
							onClick={() => setMenuExpanded(false)}
							onKeyDown={() => setMenuExpanded(false)}
						/>
						<div {...bem('nav-menu')}>
							<div {...bem('nav-menu-top')}>
								<div>
									{languageMobileOpen ? (
										<button
											{...bem('back-button')}
											onClick={() => setLanguageMobileOpen(false)}
										>
											<NavigateBeforeIcon /> <p>{t('kinto.menu.back')}</p>
										</button>
									) : (
										languageArray.length > 1 && (
											<button onClick={() => setLanguageMobileOpen(true)}>
												<p {...bem('link', 'type-desktop', 'active mobile')}>
													{language.toUpperCase()}
													{' - '}
													{t('kinto.menu.change-language')}
												</p>
											</button>
										)
									)}
								</div>
								<button tabIndex={0} onClick={toggleMenu} {...bem('burger')}>
									<Close fontSize='large' />
								</button>
							</div>

							{languageMobileOpen ? (
								<div {...bem('language-mobile')}>
									<h3 {...bem('language-modal-header')}>
										{t(`kinto.choose-language`)}
									</h3>
									{languageArray.map((item) => {
										return (
											<Link
												{...bem('language-modal-language')}
												to={item === 'sv' ? '/' : `/${item}`}
												onClick={() => setMenuExpanded(false)}
												onKeyDown={() => setMenuExpanded(false)}
												key={item}
											>
												<h3>{t(`kinto.choose-language.${item}`)}</h3>
											</Link>
										)
									})}
								</div>
							) : (
								<div {...bem('type-wrapper-mobile')}>
									<TypeLinks
										doSetSiteType={doSetSiteType}
										bemClass={'type-mobile'}
										siteType={siteType}
										language={language}
										service={service}
									/>
								</div>
							)}

							{!languageMobileOpen &&
								navigationLinks !== undefined &&
								navigationLinksSecond !== undefined && (
									<>
										<ul {...bem('main-menu')}>
											{navigationLinks
												.filter((item) => item.language === language)
												.map(({ url, label, id }) => (
													<li {...bem('menu-item')} key={id}>
														<Link
															to={url}
															onClick={() => setMenuExpanded(false)}
															{...bem('link')}
														>
															{label}
														</Link>
													</li>
												))}
										</ul>
										<div {...bem('main-menu-second')}>
											<ul>
												{navigationLinksSecond
													.filter((item) => item.language === language)
													.map(({ url, label, id }) => (
														<li {...bem('menu-item')} key={id}>
															<Link
																to={url}
																onClick={() => setMenuExpanded(false)}
																{...bem('link-second')}
															>
																<p className='h4'>{label}</p>
															</Link>
														</li>
													))}
											</ul>
											<Link
												{...bem(
													'link',
													'type-desktop',
													'active service mobile',
												)}
												to={
													service === SITE_TYPES.SHARE
														? localizedUrl('/flex', language)
														: localizedUrl('/', language)
												}
												onClick={() => {
													service === SITE_TYPES.SHARE
														? doSetService(SITE_TYPES.FLEX)
														: doSetService(SITE_TYPES.SHARE)
													doResetBookingInfo()
												}}
											>
												{service === SITE_TYPES.SHARE
													? t('kinto.header.flex')
													: t('kinto.header.share')}
											</Link>
										</div>
									</>
								)}
						</div>
					</div>
				</div>
			</nav>
		</>
	)
}

export default Header

function TypeLinks({ doSetSiteType, bemClass, siteType, language, service }) {
	const t = useTranslations()
	return (
		<>
			<Link
				to={localizedUrl('/', language, service)}
				onClick={() => {
					doSetSiteType(SITE_TYPES.PRIVATE)
				}}
				{...(siteType === SITE_TYPES.PRIVATE
					? { ...bem('link', bemClass, 'active') }
					: { ...bem('link', bemClass) })}
			>
				{t('kinto.header.private')}
			</Link>
			<Link
				to={localizedUrl('/foretag', language, service)}
				onClick={() => {
					doSetSiteType(SITE_TYPES.BUSINESS)
				}}
				{...(siteType === SITE_TYPES.BUSINESS
					? { ...bem('link', bemClass, 'active') }
					: { ...bem('link', bemClass) })}
			>
				{t('kinto.header.business')}
			</Link>
		</>
	)
}
