import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = React.forwardRef(
	({ children, to, activeClassName, partiallyActive, ...other }, ref) => {
		const isInternal = /^\/(?!\/)/.test(to)
		return isInternal ? (
			<GatsbyLink
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				{...other}
				ref={ref}
			>
				{children}
			</GatsbyLink>
		) : (
			<a href={to} ref={ref} {...other}>
				{children}
			</a>
		)
	},
)

export default Link
