import Client from '@src/api/abstract-rest-client'
import { del, put } from '@src/utils/localStorage'
import { setCookie } from '@src/utils/cookieUtil'
import { loadToken } from '@src/utils/tokenUtils'

const host = process.env.GATSBY_AZURE_API_GATEWAY_BASE_URL
// const version = process.env.GATSBY_AZURE_API_GATEWAY_REST_VERSION
let defaultHeaders = {
	'Ocp-Apim-Subscription-Key': process.env.GATSBY_AZURE_API_GATEWAY_KEY,
}
if (process.env.GATSBY_AZURE_API_GATEWAY_TRACE === 'true') {
	defaultHeaders['Ocp-Apim-Trace'] = 'true'
}

const TOKEN_KEY = 'kinto_token'

const config = {
	baseURL: `${host}`, // `${host}/${version}`, // In the futre, use versionbased api
	defaultHeaders: defaultHeaders,
}

const azureData = {
	token: loadToken(),
}

const client = new Client(config)

const parseParams = (config = {}) => {
	const { useToken, headers = {}, ...rest } = config
	if (useToken && azureData.token) {
		headers['Telematics-AuthToken'] = azureData.token
	}
	return { headers, ...rest }
}

export const setToken = (token) => {
	azureData.token = token
}

export async function azureApiGatewayGet(endpoint, params, config) {
	try {
		return await client.get({ endpoint, params, ...parseParams(config) })
	} catch (error) {
		throw error
	}
}

export async function azureApiGatewayPost(endpoint, data = {}, config = {}) {
	return await client.post({ endpoint, data, ...parseParams(config) })
}

export async function azureApiGatewayPut(endpoint, data = {}, config) {
	return await client.put({ endpoint, data, ...parseParams(config) })
}

export async function azureApiGatewayPatch(endpoint, data = {}, config) {
	return await client.patch({ endpoint, data, ...parseParams(config) })
}

export async function azureApiGatewayDel(endpoint, data = {}, config) {
	return await client.del({ endpoint, data, ...parseParams(config) })
}

export async function azureAuth(username, password, rememberToken) {
	const resp = await azureApiGatewayPost(
		'customer/authentication',
		{
			username,
			password,
		},
		{ params: { registration: true } },
	)
	const { auth_token } = resp.data
	azureData.token = auth_token
	if (rememberToken) {
		put(TOKEN_KEY, auth_token)
		setCookie(TOKEN_KEY, '')
	} else {
		del(TOKEN_KEY)
		setCookie(TOKEN_KEY, auth_token)
	}
	return resp
}

export async function azureGetMobileVerificationCode(customerId, phonenumber) {
	return await azureApiGatewayPost(
		`customer/${customerId}/send_verification_code`,
		{ phone_number: phonenumber },
		{ useToken: true, params: { registration: true } },
	)
}

export async function azureGetMobileVerificationCodeNewPhonenumber(
	customerId,
	phonenumber,
) {
	return await azureApiGatewayPost(
		`customer/${customerId}/update_phone_number_send_verification_code`,
		{ phone_number: phonenumber },
		{ useToken: true, params: { registration: true } },
	)
}

export async function fetchCustomerInfo() {
	const response = await azureApiGatewayGet(
		'customer',
		{ registration: true },
		{ useToken: true },
	)

	const {
		id,
		email,
		first_name,
		last_name,
		phone_number,
		mailing_address_display,
		can_reserve_car,
		phone_number_verified,
		has_valid_payment_card,
		driving_license,
		business_entity,
		can_reserve_car_for_business,
		can_schedule_car,
		can_schedule_car_for_business,
	} = response.data
	const address = mailing_address_display
		? mailing_address_display.replace(',', '&').split('&')
		: []
	return {
		customerId: id,
		firstName: first_name,
		lastName: last_name,
		email: email,
		phonenumber: phone_number,
		address,
		canReserve: can_reserve_car,
		phonenumberVerified: phone_number_verified,
		validPaymentCard: has_valid_payment_card,
		drivingLicense: driving_license,
		businessEntity: business_entity,
		canReserveBusiness: can_reserve_car_for_business,
		canSchedule: can_schedule_car,
		canScheduleBusiness: can_schedule_car_for_business,
	}
}

export async function remoteLogError(message, level) {
	return await client.remoteLogError(message, level)
}
