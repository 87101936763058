import React, { useState } from 'react'
import useTranslations from '@src/hooks/useTranslations'
import { TextField, InputAdornment, makeStyles } from '@material-ui/core/'

const useStyles = makeStyles({
	adornment: {
		'& > p': {
			fontSize: '.75rem',
		},
	},
})

const handleMouseDownPassword = (event) => {
	event.preventDefault()
}

export default function Password({
	values,
	touched,
	errors,
	handleChange,
	handleBlur,
}) {
	const classes = useStyles()
	const t = useTranslations()

	const [showPasswordType, setShowPasswordType] = useState(true)
	return (
		<TextField
			label={t('kinto.form.password')}
			id={t('kinto.form.password')}
			variant='outlined'
			type={showPasswordType === true ? 'password' : 'text'}
			name='password'
			value={values.password}
			required
			onChange={handleChange}
			onBlur={handleBlur}
			error={errors.password && touched.password}
			helperText={errors.password && touched.password && errors.password}
			InputProps={{
				endAdornment: (
					<InputAdornment
						className={classes.adornment}
						position='end'
						onClick={() => setShowPasswordType(!showPasswordType)}
						onMouseDown={handleMouseDownPassword}
					>
						{showPasswordType === true
							? t('kinto.form.password-show')
							: t('kinto.form.password-hide')}
					</InputAdornment>
				),
			}}
		/>
	)
}
