import React, { useState } from 'react'
import { navigate } from 'gatsby'
import useTranslations from '@src/hooks/useTranslations'
import { useConnect } from 'redux-bundler-hook'
import useSetting from '@src/hooks/useSetting'
import BEMHelper from 'react-bem-helper'
import { Formik } from 'formik'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/'
import FormContainer from '@src/components/FormContainer'
import validator from '@src/form/validations'
import Password from '@src/components/account/PasswordField'
import Email from '@src/components/account/EmailField'
import Modal from '@src/components/Modal'
import { azureApiGatewayPost } from '@src/api/azureApiGateway/client'
import Phonenumber from '@src/components/account/Phonenumber'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('registration')

const useStyles = makeStyles({
	formLabel: {
		display: 'flex',
		marginRight: 9,
	},
})

function LoginModal({
	open,
	handleClose,
	callback,
	hideRegistration,
	showRedirectInfoText = false,
	returnToBookingAfterRegistration = false,
	showTimeOutText = false,
}) {
	const t = useTranslations()
	const classes = useStyles()
	const getSetting = useSetting()
	const {
		unsuccessfulLogin,
		doLoginCustomer,
		doSetReturnToBookingAfterRegistration,
		language,
		service,
		siteType,
	} = useConnect(
		'selectUnsuccessfulLogin',
		'doLoginCustomer',
		'doSetReturnToBookingAfterRegistration',
		'selectLanguage',
		'selectService',
		'selectSiteType',
	)
	const linkRegister = getSetting('registration_popup_link', null)
	const [showResetForm, setShowResetForm] = useState(false)

	return (
		<Modal open={open} handleClose={handleClose} xButton>
			<div {...bem('')}>
				{showResetForm ? (
					<>
						<h3 {...bem('title')}>{t('kinto.register.reset-login-title')}</h3>
						<p {...bem('description')}>
							{t('kinto.register.reset-login-description')}
						</p>
						<Divider />
						<div {...bem('container')}>
							<Formik
								initialValues={{
									phonenumber: '',
								}}
								validationSchema={validator({
									phonenumber: {
										validatorKey: 'phonenumber',
										required: true,
									},
								})}
							>
								{(props) => {
									return (
										<FormContainer>
											<Phonenumber {...props} />
											<Button
												aria-label={t('kinto.register.reset-password')}
												variant='contained'
												color='primary'
												onClick={async () => {
													setShowResetForm(false)
													await azureApiGatewayPost('customer/reset_password', {
														phone_number: props.values.phonenumber,
													})
												}}
												disabled={
													!!props.errors.phonenumber ||
													!props.touched.phonenumber
												}
											>
												{t('kinto.register.reset-password')}
											</Button>
										</FormContainer>
									)
								}}
							</Formik>
						</div>
					</>
				) : (
					<>
						<h3 {...bem('title')}>{t('kinto.register.login')}</h3>
						{showRedirectInfoText && (
							<div {...bem('container')}>
								<p>{t('kinto.register.redirect-info-text')}</p>
							</div>
						)}
						{showTimeOutText && (
							<div {...bem('container')}>
								<p>{t('kinto.register.timeout-info-text')}</p>
							</div>
						)}

						<Divider />
						<div {...bem('container')}>
							<Formik
								initialValues={{
									email: '',
									password: '',
									keepLoggedIn: false,
								}}
								validationSchema={validator({
									email: {
										validatorKey: 'email',
										required: true,
									},
									password: {
										validatorKey: 'password',
										required: true,
									},
								})}
							>
								{(props) => {
									const { values, handleChange } = props
									return (
										<FormContainer>
											<Email {...props} />
											<Password {...props} />
											<FormControlLabel
												className={classes.formLabel}
												control={
													<Checkbox
														className={classes.checkbox}
														checked={values.keepLoggedIn}
														name='keepLoggedIn'
														color='primary'
														onChange={handleChange}
													/>
												}
												label={t('kinto.register.rememberme')}
											/>
											{unsuccessfulLogin && (
												<div {...bem('unsuccessfull-login')}>
													{t('kinto.register.unsuccessfull-login')}
												</div>
											)}
											<Button
												aria-label={t('kinto.register.login')}
												variant='contained'
												color='primary'
												onClick={async () => {
													const customerInfo = await doLoginCustomer(
														values.email,
														values.password,
														values.keepLoggedIn,
													)
													// if login failed customerInfo will be false
													if (customerInfo) {
														handleClose()
														if (callback) {
															callback(customerInfo)
														}
													}
												}}
											>
												{t('kinto.register.login')}
											</Button>
											<Button
												aria-label={t('kinto.register.reset-password')}
												color='primary'
												onClick={() => {
													setShowResetForm(true)
												}}
											>
												{t('kinto.register.reset-password')}
											</Button>
											{!hideRegistration && (
												<Button
													aria-label={t('kinto.register.become-member')}
													variant='outlined'
													color='primary'
													onClick={() => {
														if (returnToBookingAfterRegistration === true) {
															doSetReturnToBookingAfterRegistration(true)
														}
														navigate(
															language === 'sv' &&
																siteType === SITE_TYPES.PRIVATE &&
																service === SITE_TYPES.SHARE
																? linkRegister
																: 'bli-medlem',
														)
														handleClose()
													}}
												>
													{t('kinto.register.become-member')}
												</Button>
											)}
										</FormContainer>
									)
								}}
							</Formik>
						</div>
					</>
				)}
			</div>
		</Modal>
	)
}

export default LoginModal
