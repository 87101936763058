import React from 'react'
import { transformPhonenumber } from '@src/form/transformation'
import { TextField } from '@material-ui/core/'
import useTranslations from '@src/hooks/useTranslations'

export default function Phonenumber({
	values,
	touched,
	errors,
	handleChange,
	handleBlur,
}) {
	const t = useTranslations()
	return (
		<TextField
			label={t('kinto.form.phonenumber')}
			id={t('kinto.form.phonenumber')}
			variant='outlined'
			name='phonenumber'
			required
			value={transformPhonenumber(values.phonenumber)}
			onChange={(event) => {
				const ev = { ...event }
				ev.target.value = transformPhonenumber(ev.target.value)
				handleChange(event)
			}}
			onBlur={handleBlur}
			error={errors.phonenumber && touched.phonenumber}
			helperText={
				errors.phonenumber && touched.phonenumber && errors.phonenumber
			}
		/>
	)
}
