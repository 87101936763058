import React from 'react'
import Helmet from 'react-helmet'
import useSetting from '@src/hooks/useSetting'

export default function CookiesApprovalPopup() {
	const getSetting = useSetting()
	const onetrustId = getSetting('onetrust_id')

	return (
		<Helmet>
			<script
				type='text/javascript'
				src={`https://cdn.cookielaw.org/consent/${onetrustId}/OtAutoBlock.js`}
				charset='UTF-8'
			></script>
			<script
				src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
				type='text/javascript'
				charset='UTF-8'
				data-document-language='true'
				data-domain-script={onetrustId}
			></script>
			<script
				type='application/ld+json'
				dangerouslySetInnerHTML={{
					__html: `
						function OptanonWrapper() {}
					`,
				}}
			/>
		</Helmet>
	)
}
