import variables from '../styles/1__settings/_vars.module.scss'

const smallTablet = `@media (min-width: ${variables.breakpointSm})`
const tablet = `@media (min-width: ${variables.breakpointMd})`
const desktop = `@media (min-width: ${variables.breakpointLg})`

export const themeCustom = {
	breakpoints: {
		values: {
			xs: variables.breakpointXs,
			sm: variables.breakpointSm,
			md: variables.breakpointMd,
			lg: variables.breakpointLg,
			xl: variables.breakpointXl,
		},
	},
	palette: {
		common: {
			black: variables.colorBlack,
		},
		primary: {
			xlight: variables.colorPrimaryXLight,
			light: variables.colorPrimaryLight,
			main: variables.colorPrimary,
			dark: variables.colorPrimaryDark,
			xdark: variables.colorPrimaryXDark,
		},
		secondary: {
			main: variables.colorWhite,
		},
		error: {
			main: variables.colorError,
		},
		info: {
			main: variables.colorInfo,
		},
		success: {
			main: variables.colorSuccess,
		},
		text: {
			primary: variables.colorBlack,
		},
	},
	typography: {
		fontFamily: '"Toyota Type Book", "Arial", sans-serif',
		button: {
			fontSize: '1rem',
			textTransform: variables.textReset,
		},
	},

	//Overrides components
	overrides: {
		//Container override
		MuiContainer: {
			root: {
				margin: 'auto',

				[smallTablet]: {
					paddingRight: '1.5rem',
					paddingLeft: '1.5rem',
				},
				[tablet]: {
					paddingRight: '1.25rem',
					paddingLeft: '1.25rem',
				},
				[desktop]: {
					maxWidth: '68.25rem',
				},
			},
			maxWidthMd: {
				[desktop]: {
					maxWidth: 'none',
					padding: 0,
				},
			},
		},
		MuiRadio: { root: { color: variables.colorPrimary } },
		MuiButton: {
			root: {
				fontWeight: 'bold',
			},
			contained: {
				paddingTop: '12px !important',
				paddingBottom: '12px !important',
				'&$disabled': {
					backgroundColor: variables.colorBackgroundDark,
					color: variables.colorTextDisabled,
				},
			},
			containedPrimary: {
				backgroundColor: variables.colorPrimaryDark,
				'&:hover': {
					backgroundColor: variables.colorPrimaryXDark,
				},
			},
			containedSecondary: {
				backgroundColor: variables.colorWhite,
				color: variables.colorPrimaryDark,
				boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
				'&:hover': {
					backgroundColor: variables.colorBackground,
					color: variables.colorPrimaryXDark,
					boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
				},
			},
			outlinedPrimary: {
				backgroundColor: variables.colorWhite,
				color: variables.colorPrimaryDark,
				border: `1px solid ${variables.colorPrimaryDark}`,
				'&:hover': {
					color: variables.colorPrimaryXDark,
					backgroundColor: variables.colorBackground,
					border: `1px solid ${variables.colorPrimaryXDark}`,
				},
				'&$disabled': {
					borderColor: variables.colorBackgroundDark,
					color: variables.colorTextDisabled,
				},
			},
			outlined: {
				paddingTop: '11px !important',
				paddingBottom: '11px !important',
			},
			label: {
				lineHeight: '24px',
				fontWeight: 'bold',
			},
		},

		MuiExpansionPanel: {
			root: {
				border: `1px solid ${variables.colorBorder}`,
				borderBottomWidth: 0,
				backgroundColor: 'rgba(0, 0, 0, 0)',
				boxShadow: 'none',
				'&:last-child': {
					borderBottom: `1px solid ${variables.colorBorder}`,
				},
				'&:before': {
					display: 'none',
				},
			},
		},
		MuiExpansionPanelSummary: {
			root: {
				padding: '0 1rem',
				fontWeight: 'bold',
			},
			content: {
				margin: '1rem 0',
			},
			expandIcon: {
				color: variables.colorBlack,
			},
		},
		MuiExpansionPanelDetails: {
			root: {
				padding: '.5rem 1rem 1rem',
			},
		},
		MuiPaper: {
			root: {
				marginBottom: '.5rem',
				border: 'solid 1px #e3e9eb',
			},
			elevation1: {
				boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
			},
		},
		MuiCardContent: {
			root: {
				padding: '1.5rem 1.25rem .5rem 1.25rem',
			},
		},
		MuiCardActions: {
			root: {
				padding: '.5rem 1.25rem 1.25rem',
			},
		},
		MuiInputBase: {
			input: {
				height: '24px',
			},
		},
		MuiOutlinedInput: {
			input: {
				padding: '11px 15px 15px !important',
			},
		},
		MuiInputLabel: {
			outlined: {
				transform: 'translate(14px, 17px) scale(1)',
			},
		},
	},
}
