import '@src/styles/index.scss'

import React, { useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from '@src/components/layout/header/Header'
import Footer from '@src/components/layout/Footer'
import CookiesApprovalPopup from '@src/components/CookiesApprovalPopup'
import { graphql, useStaticQuery } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import useTranslations from '@src/hooks/useTranslations'
import useBrowserIE from '@src/hooks/useBrowserIE'

const query = graphql`
	query GetPimcoreVehicles {
		pimcore {
			getVehicleTypeListing {
				edges {
					node {
						id
						VehicleType
						Brand
						AddtionalInformation
						Model
						NoOfSeats
						RatePerHour
						RatePerKilometer
						RatePerHourBusiness
						RatePerKilometerBusiness
						FlexPrice
						FlexPriceBusiness
						FlexPriceExtraText
						RidecellID
						Images {
							image {
								filename
								fullpath(thumbnail: "car_list")
								srcset(thumbnail: "car_list") {
									descriptor
									url
									resolutions {
										url
										resolution
									}
								}
							}
						}
						MainImage {
							fullpath(thumbnail: "car_main")
						}
						Size
						Towbar
						ModalDocumentLink {
							path
						}
					}
				}
			}
		}
	}
`

const Layout = ({ children, location }) => {
	const t = useTranslations()
	const embeddable = location && location.pathname.endsWith('-embeddable')
	const isStationsPage = location && location.pathname.indexOf('boka-bil') >= 0

	const { pimcore } = useStaticQuery(query)
	const vehicleTypes = pimcore.getVehicleTypeListing.edges.reduce(
		(accumulator, { node }) => [...accumulator, node],
		[],
	)
	const vehicleTypesRef = useRef(vehicleTypes)
	const { doInitializeVehicleTypes } = useConnect('doInitializeVehicleTypes')
	const init = useCallback(
		() => doInitializeVehicleTypes(vehicleTypesRef.current),
		[doInitializeVehicleTypes],
	)

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		if (params.get('b2b-invite') !== null) {
			if (params.get('b2b-invite') === '1') {
				sessionStorage.setItem('b2b-invite', 'showField')
			} else {
				sessionStorage.setItem('b2b-invite', params.get('b2b-invite'))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		init()
	}, [init])

	const isIE = useBrowserIE()

	return (
		<>
			{isIE ? (
				<div id='ieNotSupported'>
					<h2>{t('kinto.ie.title')}</h2>
					<p>{t('kinto.ie.description')}</p>
				</div>
			) : (
				<>
					{!embeddable && <CookiesApprovalPopup />}
					<div className='layout'>
						{!embeddable && <Header location={location} />}
						<main>
							<div>{children}</div>
						</main>
						{!embeddable && !isStationsPage && <Footer location={location} />}
					</div>
				</>
			)}
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.any,
	location: PropTypes.any.isRequired,
}

export default Layout
