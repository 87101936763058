import { navigate } from 'gatsby'

const push = (data) =>
	new Promise((resolve, reject) => {
		if (!data) {
			reject('No data to push')
		}

		if (
			window['google_tag_manager'] &&
			typeof window.dataLayer !== 'undefined'
		) {
			if (process.env.GATSBY_GOOGLE_SERVICE_LOG === 'true') {
				console.info('Pushing to Google Analytics: ', data)
			}
			resolve(window.dataLayer.push(data))
		} else {
			if (data.eventCallback) {
				data.eventCallback()
			}
			reject('GTM not installed')
		}
	})

const pushDropDownFirstPage = async () => {
	return push({
		event: 'InteractionDropDownFirstPage',
		eventTimeout: 200,
		transport: 'beacon',
	})
}

const pushVirtualPageView = async (pageUrl, pageTitle = {}) => {
	return push(
		!(pageUrl || pageTitle)
			? null
			: {
					event: 'VirtualPageviewEvent',
					virtualPageURL: pageUrl,
					virtualPageTitle: pageTitle,
			  },
	)
}

const pushProductImpressions = async (impressions = {}) => {
	return push(
		!impressions || impressions.length === 0
			? null
			: {
					event: 'EEProductImpressions',
					ecommerce: {
						currencyCode: 'SEK',
						impressions: impressions,
					},
			  },
	)
}

const pushCheckoutStep = async (stepNumber, products = []) => {
	return push(
		products.length < 1
			? null
			: {
					event: 'EECheckoutStep',
					ecommerce: {
						checkout: {
							actionField: { step: stepNumber },
							products: products,
						},
					},
			  },
	)
}

const pushAddToCart = async (product) => {
	return push(
		product
			? {
					event: 'EEAddToCart',
					ecommerce: {
						currencyCode: 'SEK',
						add: {
							products: [product],
						},
					},
			  }
			: null,
	)
}

const pushRemoveFromCart = async (product) => {
	return push(
		product
			? {
					event: 'EERemoveFromCart',
					ecommerce: {
						remove: {
							products: [product],
						},
					},
			  }
			: null,
	)
}

const pushPurchase = async (order) => {
	const products = order.items.map((item) => {
		const { sku, name, quantity, price } = item
		return { id: sku, name, quantity, price }
	})

	return push(
		!products || !products.length || products.length < 1
			? null
			: {
					event: 'Transaction',
					ecommerce: {
						purchase: {
							actionField: {
								id: order.order_id,
								revenue: order.grand_total,
								tax: order.tax,
								shipping: order.shipping_cost,
							},
							products: products,
						},
					},
			  },
	)
}

const pushPromotionClick = async (promotion, url) => {
	return push(
		promotion
			? {
					event: 'EEPromotionClick',
					ecommerce: {
						promoClick: {
							promotions: [promotion],
						},
					},
					eventCallback: () => {
						navigate(url)
					},
					eventTimeout: 200,
					transport: 'beacon',
			  }
			: null,
	)
}

export const googleAnalytics = {
	pushDropDownFirstPage,
	pushVirtualPageView,
	pushProductImpressions,
	pushCheckoutStep,
	pushAddToCart,
	pushRemoveFromCart,
	pushPurchase,
	pushPromotionClick,
}
