import * as Yup from 'yup'

import useTranslations from '@src/hooks/useTranslations'

const ssoRegExp = /^(19|20)?(\d{6}(-|\s)\d{4}|(?!19|20)\d{10})$/g

// Accepts the format on swedish drivers license
// eslint-disable-next-line no-useless-escape
const dateRegExp = /^(([0-2][0-9]|(3)[0-1])[\.\-](0[1-9]|1[0-2])[\.\-]([2][0-9]{3}))|(([2][0-9]{3})[\.\-](0[1-9]|1[0-2])[\.\-]([0-2][0-9]|(3)[0-1]))$/g

function equalTo(ref, msg) {
	return this.test({
		name: 'equalTo',
		exclusive: false,
		message: msg,
		params: {
			reference: ref.path,
		},
		test: function(value) {
			return value === this.resolve(ref)
		},
	})
}

Yup.addMethod(Yup.string, 'equalTo', equalTo)

function oneOfLengths(lengths, msg) {
	return this.test({
		name: 'oneOfLengths',
		exclusive: false,
		message: msg,
		test: function(value) {
			if (!value) return false
			return lengths.find((length) => length === value.length)
		},
	})
}

Yup.addMethod(Yup.string, 'oneOfLengths', oneOfLengths)

const validators = {
	sso: function(t) {
		return Yup.string()
			.min(10)
			.max(16)
			.matches(ssoRegExp, t('kinto.error.sso'))
	},
	date: function(t) {
		return Yup.string()
			.oneOfLengths([10], t('kinto.error.date'))
			.matches(dateRegExp, t('kinto.error.date'))
	},
	name: function(t) {
		return Yup.string()
			.matches(
				/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:.,[\]]+$/,
				t('kinto.error.name-no-numbers'),
			)
			.min(2, t('kinto.error.name-over-two'))
			.max(25, t('kinto.error.less-than-25'))
	},
	email: function(t) {
		return Yup.string()
			.email(t('kinto.error.email'))
			.max(45, t('kinto.error.less-than-45'))
	},
	secondEmail: function(t) {
		return Yup.string().equalTo(Yup.ref('email'), t('kinto.error.second-email'))
	},
	password: function(t) {
		return Yup.string()
			.matches(
				/^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])(?=.*\d).{8,}$/,
				t('kinto.error.password'),
			)
			.max(1024, t('kinto.error.less-than-1024'))
	},
	verificationCode: function(t) {
		return Yup.string().matches(/^[0-9]{4}$/, t('kinto.error.validation-code'))
	},
	phonenumber: function(t) {
		return Yup.string().matches(/^[+]\d{7,15}$/, t('kinto.error.phonenumber'))
	},
	businessCode: function(t) {
		return Yup.string().min(1, t('kinto.error.code-required'))
	},
}

export default function(validations) {
	const t = useTranslations()
	const _validations = {}
	for (let [key, value] of Object.entries(validations)) {
		const { validatorKey, ...options } = value
		_validations[key] = createValidation(validatorKey, options, t)
	}
	return Yup.object().shape(_validations)
}

function createValidation(key, options = {}, t) {
	const validator = validators[key] ? validators[key](t) : null
	if (validator) {
		if (options.required) {
			return validator.required(t('kinto.error.required'))
		}
		return validator
	}

	throw new Error(`Validator: [${key}] does not exist!`)
}
