import './BookingModal.scss'
import React, { useState } from 'react'
import BEMHelper from 'react-bem-helper'
import { useConnect } from 'redux-bundler-hook'
import useTranslations from '@src/hooks/useTranslations'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import image from '@src/assets/images/Kinto_missing_information.jpg'
import Modal from '@src/components/Modal'
import { SITE_TYPES } from '@src/bundles/site'
import Link from '@src/components/Link'

const bem = new BEMHelper('booking-modal')

function BookingModal({ open, handleClose }) {
	const t = useTranslations()
	const [checkboxChecked, setCheckboxChecked] = useState(false)

	const { doSetSiteType, isMissingInformation } = useConnect(
		'doSetSiteType',
		'selectIsMissingInformation',
	)

	const handleChecked = (event) => {
		setCheckboxChecked(event.target.checked)
	}

	const rememberChecked = () => {
		if (checkboxChecked) {
			localStorage.setItem('hide-popup', true)
		}
	}

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			bemClass={'small'}
			xButtonAbsolute
			noMargin
		>
			<div {...bem('')}>
				<div {...bem('image')}>
					<img src={image} alt='' />
				</div>

				<div {...bem('content')}>
					<p {...bem('text')}>
						{isMissingInformation
							? t('kinto.booking-modal.business-account')
							: t('kinto.booking-modal.two-accounts')}
					</p>
					<Button
						aria-label={t('kinto.booking-modal.business')}
						variant='contained'
						color='primary'
						fullWidth
						onClick={() => {
							doSetSiteType(SITE_TYPES.BUSINESS)
							rememberChecked()
							handleClose()
						}}
					>
						{t('kinto.booking-modal.business')}
					</Button>
					<Button
						aria-label={t('kinto.booking-modal.private')}
						variant='contained'
						color='primary'
						fullWidth
						disabled={isMissingInformation}
						onClick={() => {
							doSetSiteType(SITE_TYPES.PRIVATE)
							handleClose()
						}}
					>
						{t('kinto.booking-modal.private')}
					</Button>
					{isMissingInformation && (
						<>
							<p {...bem('missing-info')}>
								{t('kinto.booking-modal.missing-info', [
									<Link
										{...bem('link')}
										key={'popup-link'}
										to={'/kom-igang'}
										onClick={() => {
											handleClose()
										}}
									>
										{t('kinto.booking-modal.missing-info.link')}
									</Link>,
								])}
							</p>

							<FormControlLabel
								control={
									<Checkbox
										color='primary'
										checked={checkboxChecked}
										onChange={handleChecked}
									/>
								}
								label={t('kinto.booking-modal.hide-popup')}
							/>
						</>
					)}
				</div>
			</div>
		</Modal>
	)
}

export default BookingModal
