export const localizedUrl = (url, language, service) => {
	if (language === 'sv' && service === 'flex') {
		return `/flex${url}`
	} else if (language === 'sv') {
		return url
	} else if (language !== 'sv' && service === 'flex') {
		return `/${language}/flex${url}`
	} else {
		return `/${language}${url}`
	}
}
